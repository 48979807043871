<template>
	<div class="textarea__input"
	v-bind:class="{
		focus: isFocused,
	}" 	
	v-bind:style="{
		height: height
	}"> 
		<textarea v-model="value"
			ref="textarea"
			@focus="isFocused = true"
			@blur="isFocused = false"

			:placeholder="placeholder">
		</textarea>	
	</div>
</template>

<script>
export default {
	name: 'TextareaInput',
	model: {
		prop: 'text',
		event: 'update:text'
	},
	props: {
		text: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			default: ''
		},
		height: {
			type: String,
			default: 'auto'
		}
	},
    computed: {
        value: {
            get() {
                return this.text
            },
            set(newValue) {
                this.$emit('update:text', newValue)
            }
        }
    },
	data() {
		return {
			isFocused: false,
		};
	},
	methods: {
		resize() {
			if (this.height == "auto") return

			let element = this.$refs["textarea"];
			element.style.height = "32px";
			element.style.height= element.scrollHeight + "px";

			if (this.value.length == 0){
				element.style.height = "32px";
				return
			}
		},
	},
};
</script>

<style scoped>


.textarea__input{
	border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    height: 38px;
    max-height: 70px;

    width: 100%;
}

.textarea__input.focus{
    border-color: hwb(212 0% 0%);
    box-shadow: 0 0 0 2px #cbe3ff;
}

.textarea__input.error{
    border-color: 	#ff3333;
    box-shadow: 0 0 0 2px #ffcece;
}

textarea{
	font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    padding: 11px;
    border: none;
    border-radius: 4px;
    width: 100%;
	height: 100%;

    resize: none;
    overflow-y: auto;
}

textarea:focus{
    outline: none;
}
</style>